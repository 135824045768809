/**
 * @generated SignedSource<<818360604cc8c89b0e24b9834b0765fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnouncementBannerDisplayMode = "ALL_PAGES" | "CHAIN" | "HOMEPAGE_ONLY" | "%future added value";
export type AnnouncementBannerVariant = "DANGER" | "DEFAULT" | "NEW_FEATURE" | "WARNING" | "%future added value";
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type AnnouncementBannerQuery$variables = {};
export type AnnouncementBannerQuery$data = {
  readonly announcementBanner: {
    readonly chain: {
      readonly identifier: ChainIdentifier;
    } | null;
    readonly ctaText: string;
    readonly displayMode: AnnouncementBannerDisplayMode;
    readonly heading: string;
    readonly headingMobile: string;
    readonly icon: string | null;
    readonly isClosable: boolean | null;
    readonly leftAlign: boolean;
    readonly relayId: string;
    readonly text: string;
    readonly url: string | null;
    readonly variant: AnnouncementBannerVariant | null;
    readonly " $fragmentSpreads": FragmentRefs<"NewFeatureAnnouncementBanner_data">;
  } | null;
};
export type AnnouncementBannerQuery = {
  response: AnnouncementBannerQuery$data;
  variables: AnnouncementBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headingMobile",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ctaText",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayMode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variant",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClosable",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leftAlign",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnouncementBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnnouncementBannerType",
        "kind": "LinkedField",
        "name": "announcementBanner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewFeatureAnnouncementBanner_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AnnouncementBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnnouncementBannerType",
        "kind": "LinkedField",
        "name": "announcementBanner",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ced5593daa43994d674b65ad4262bf5",
    "id": null,
    "metadata": {},
    "name": "AnnouncementBannerQuery",
    "operationKind": "query",
    "text": "query AnnouncementBannerQuery {\n  announcementBanner {\n    relayId\n    text\n    url\n    heading\n    headingMobile\n    ctaText\n    chain {\n      identifier\n    }\n    displayMode\n    variant\n    isClosable\n    icon\n    leftAlign\n    ...NewFeatureAnnouncementBanner_data\n    id\n  }\n}\n\nfragment NewFeatureAnnouncementBanner_data on AnnouncementBannerType {\n  text\n  url\n  heading\n  headingMobile\n  ctaText\n  isClosable\n  icon\n}\n"
  }
};
})();

(node as any).hash = "7ed52f96403d5412a78bd66c53508259";

export default node;
