/**
 * @generated SignedSource<<521023becccfeba68908227f87eade12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useIsDelegatedQuery$variables = {
  chain?: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value" | null;
  identifier: string;
};
export type useIsDelegatedQuery$data = {
  readonly accountHelpers: {
    readonly isDelegated: boolean;
  };
};
export type useIsDelegatedQuery = {
  response: useIsDelegatedQuery$data;
  variables: useIsDelegatedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identifier"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountHelpersType",
    "kind": "LinkedField",
    "name": "accountHelpers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "chain",
            "variableName": "chain"
          },
          {
            "kind": "Variable",
            "name": "identifier",
            "variableName": "identifier"
          }
        ],
        "kind": "ScalarField",
        "name": "isDelegated",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useIsDelegatedQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useIsDelegatedQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b842046d440dec7566772d35087381e1",
    "id": null,
    "metadata": {},
    "name": "useIsDelegatedQuery",
    "operationKind": "query",
    "text": "query useIsDelegatedQuery(\n  $identifier: String!\n  $chain: ChainScalar\n) {\n  accountHelpers {\n    isDelegated(identifier: $identifier, chain: $chain)\n  }\n}\n"
  }
};
})();

(node as any).hash = "81e24762e321a31d0db5e826ec89f57f";

export default node;
