/**
 * @generated SignedSource<<e6cff56048d0a7d5c3f8b4aa8b7435c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getBlockchainActionsCancelAllOrdersActionQuery$variables = {
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value";
};
export type getBlockchainActionsCancelAllOrdersActionQuery$data = {
  readonly blockchain: {
    readonly bulkCancelAllOrdersActions: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_cancel_all_orders">;
    }>;
  };
};
export type getBlockchainActionsCancelAllOrdersActionQuery = {
  response: getBlockchainActionsCancelAllOrdersActionQuery$data;
  variables: getBlockchainActionsCancelAllOrdersActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chain"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AddressDataType",
    "kind": "LinkedField",
    "name": "source",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AddressDataType",
    "kind": "LinkedField",
    "name": "destination",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "data",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getBlockchainActionsCancelAllOrdersActionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockchainType",
        "kind": "LinkedField",
        "name": "blockchain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CancelAllOrdersActionType",
            "kind": "LinkedField",
            "name": "bulkCancelAllOrdersActions",
            "plural": true,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "useHandleBlockchainActions_cancel_all_orders",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionSubmissionDataType",
                    "kind": "LinkedField",
                    "name": "method",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "useHandleBlockchainActions_transaction",
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "useTransaction_transaction",
                            "selections": (v5/*: any*/),
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getBlockchainActionsCancelAllOrdersActionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockchainType",
        "kind": "LinkedField",
        "name": "blockchain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CancelAllOrdersActionType",
            "kind": "LinkedField",
            "name": "bulkCancelAllOrdersActions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionSubmissionDataType",
                "kind": "LinkedField",
                "name": "method",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fb0b3a50fafe7974f4623535841adb4",
    "id": null,
    "metadata": {},
    "name": "getBlockchainActionsCancelAllOrdersActionQuery",
    "operationKind": "query",
    "text": "query getBlockchainActionsCancelAllOrdersActionQuery(\n  $chain: ChainScalar!\n) {\n  blockchain {\n    bulkCancelAllOrdersActions(chain: $chain) {\n      ...useHandleBlockchainActions_cancel_all_orders\n    }\n  }\n}\n\nfragment useHandleBlockchainActions_cancel_all_orders on CancelAllOrdersActionType {\n  method {\n    ...useHandleBlockchainActions_transaction\n  }\n}\n\nfragment useHandleBlockchainActions_transaction on TransactionSubmissionDataType {\n  chain {\n    identifier\n  }\n  ...useTransaction_transaction\n}\n\nfragment useTransaction_transaction on TransactionSubmissionDataType {\n  chain {\n    identifier\n  }\n  source {\n    value\n  }\n  destination {\n    value\n  }\n  value\n  data\n}\n"
  }
};
})();

(node as any).hash = "62c4838ce7b53a8e4a0a2e3908caff5c";

export default node;
