/**
 * @generated SignedSource<<07dfb70bfdd7d403f9f84933fe6777a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type item_sellUrl$data = {
  readonly __typename: "AssetBundleType";
  readonly assetQuantities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly relayId: string;
        };
      } | null;
    } | null>;
  };
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly slug: string | null;
  readonly " $fragmentType": "item_sellUrl";
} | {
  readonly __typename: "AssetType";
  readonly " $fragmentSpreads": FragmentRefs<"asset_url">;
  readonly " $fragmentType": "item_sellUrl";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "item_sellUrl";
};
export type item_sellUrl$key = {
  readonly " $data"?: item_sellUrl$data;
  readonly " $fragmentSpreads": FragmentRefs<"item_sellUrl">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "item_sellUrl"
};

(node as any).hash = "8c03d6a807eaf65702e5e05be3cba2f9";

export default node;
