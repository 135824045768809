/**
 * @generated SignedSource<<9c0fae24bf520057bf8cf150adf7d2d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_transaction$data = {
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useTransaction_transaction">;
  readonly " $fragmentType": "useHandleBlockchainActions_transaction";
};
export type useHandleBlockchainActions_transaction$key = {
  readonly " $data"?: useHandleBlockchainActions_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_transaction"
};

(node as any).hash = "135dc81693d430378e38531d55f9d660";

export default node;
