/**
 * @generated SignedSource<<342e8689a7292747998fdeb2d6b046f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type Network = "MAINNET" | "TESTNET" | "%future added value";
export type useChainsQuery$variables = {};
export type useChainsQuery$data = {
  readonly chains: ReadonlyArray<{
    readonly blockExplorer: {
      readonly addressUrlTemplate: string;
      readonly name: string;
      readonly transactionUrlTemplate: string;
      readonly url: string;
    };
    readonly canonicalChain: ChainIdentifier;
    readonly description: string;
    readonly displayName: string;
    readonly identifier: ChainIdentifier;
    readonly isEvm: boolean;
    readonly isMintingToolSupported: boolean;
    readonly logo: string;
    readonly nativeCurrency: {
      readonly decimals: number;
      readonly name: string;
      readonly symbol: string;
    };
    readonly nativeCurrencyPaymentAsset: {
      readonly usdPrice: string;
    } | null;
    readonly network: Network;
    readonly networkId: string | null;
    readonly publicRpcUrl: string;
    readonly shortDescription: string;
    readonly showFilters: boolean;
    readonly websiteUrl: string;
    readonly wrappedCurrency: {
      readonly symbol: string;
    };
  }>;
};
export type useChainsQuery = {
  response: useChainsQuery$data;
  variables: useChainsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifier",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicRpcUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "networkId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "websiteUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "network",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEvm",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canonicalChain",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMintingToolSupported",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showFilters",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "BlockExplorerType",
  "kind": "LinkedField",
  "name": "blockExplorer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionUrlTemplate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressUrlTemplate",
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "CurrencyType",
  "kind": "LinkedField",
  "name": "nativeCurrency",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimals",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "CurrencyType",
  "kind": "LinkedField",
  "name": "wrappedCurrency",
  "plural": false,
  "selections": [
    (v15/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usdPrice",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useChainsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChainType",
        "kind": "LinkedField",
        "name": "chains",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentAssetType",
            "kind": "LinkedField",
            "name": "nativeCurrencyPaymentAsset",
            "plural": false,
            "selections": [
              (v18/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useChainsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChainType",
        "kind": "LinkedField",
        "name": "chains",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentAssetType",
            "kind": "LinkedField",
            "name": "nativeCurrencyPaymentAsset",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a37944df35c74646452b05c7c522311",
    "id": null,
    "metadata": {},
    "name": "useChainsQuery",
    "operationKind": "query",
    "text": "query useChainsQuery {\n  chains {\n    identifier\n    displayName\n    publicRpcUrl\n    networkId\n    websiteUrl\n    logo\n    network\n    isEvm\n    canonicalChain\n    description\n    shortDescription\n    isMintingToolSupported\n    showFilters\n    blockExplorer {\n      url\n      transactionUrlTemplate\n      addressUrlTemplate\n      name\n    }\n    nativeCurrency {\n      symbol\n      name\n      decimals\n    }\n    wrappedCurrency {\n      symbol\n    }\n    nativeCurrencyPaymentAsset {\n      usdPrice\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "970d9da29fb3948a9e3e588bcb4c254a";

export default node;
