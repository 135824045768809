/**
 * @generated SignedSource<<a1ba57dfbfa7ac47172422dd683b126c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type collectionRecentViews_data$data = {
  readonly defaultChain: {
    readonly identifier: ChainIdentifier;
  };
  readonly isVerified: boolean;
  readonly logo: string | null;
  readonly name: string;
  readonly relayId: string;
  readonly slug: string;
  readonly statsV2: {
    readonly totalSupply: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  readonly " $fragmentType": "collectionRecentViews_data";
};
export type collectionRecentViews_data$key = {
  readonly " $data"?: collectionRecentViews_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"collectionRecentViews_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "collectionRecentViews_data"
};

(node as any).hash = "865403e51f5a2e64204e347bf53742d0";

export default node;
